import React from "react"

export const Logo: React.FC = () => (
  <svg style={{ 'max-width': '24px' }} width="74px" height="74px" viewBox="0 0 74 74" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Resume" transform="translate(-778.000000, -1703.000000)">
              <g id="Resume-1" transform="translate(678.000000, 1603.000000)">
                  <g id="Simbolo" transform="translate(100.000000, 100.000000)">
                      <circle id="Oval" fill="#FFFFFF" cx="37" cy="37" r="37"></circle>
                      <path d="M32.52,52.32 C33.9733333,52.32 35.26,52.0866667 36.38,51.62 C37.5,51.1533333 38.4433333,50.4833333 39.21,49.61 C39.9766667,48.7366667 40.5566667,47.6666667 40.95,46.4 C41.3433333,45.1333333 41.54,43.7 41.54,42.1 L41.54,42.1 L41.54,23.34 L37.66,23.34 L37.66,42.18 C37.66,43.38 37.5366667,44.4166667 37.29,45.29 C37.0433333,46.1633333 36.6866667,46.88 36.22,47.44 C35.7533333,48 35.1733333,48.4133333 34.48,48.68 C33.7866667,48.9466667 33,49.08 32.12,49.08 C31.4533333,49.08 30.9233333,49.02 30.53,48.9 C30.1366667,48.78 29.82,48.72 29.58,48.72 C29.2866667,48.72 29.07,48.79 28.93,48.93 C28.79,49.07 28.7066667,49.2533333 28.68,49.48 C28.6533333,49.8533333 28.62,50.23 28.58,50.61 C28.54,50.99 28.5066667,51.3733333 28.48,51.76 C29.8533333,52.1333333 31.2,52.32 32.52,52.32 Z" id="J" fill="#000000" fill-rule="nonzero"></path>
                  </g>
              </g>
          </g>
      </g>
  </svg>
)
