import { createStitches } from "@stitches/react"

export const { styled, css, getCssText } = createStitches({
  theme: {
    colors: {
      $black: "#111",
      $white: "#fff",

      $gray400: "#AAAAAA",
      $gray500: "#676767",
      $gray600: "#878787",
      $gray700: "#454545",
      $gray800: "#2A2A2A",
      $gray900: "#161616",
    },
    space: {
      $px: "1px",
      $0: "0",
      $1: "0.25rem",
      $2: "0.5rem",
      $3: "0.75rem",
      $4: "1rem",
      $5: "1.25rem",
      $6: "1.5rem",
      $8: "2rem",
      $10: "2.5rem",
      $12: "3rem",
      $16: "4rem",
      $20: "5rem",
      $24: "6rem",
      $32: "8rem",
      $40: "10rem",
      $48: "12rem",
      $56: "14rem",
      $64: "16rem",
    },
    fontSizes: {
      $xs: "0.75rem",
      $sm: "0.875rem",
      $base: "1rem",
      $lg: "1.125rem",
      $xl: "1.25rem",
      $2xl: "1.5rem",
      $3xl: "1.875rem",
      $4xl: "2.25rem",
      $5xl: "3rem",
      $6xl: "4rem",
    },
    fonts: {
      $default: [
        "Lato",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        '"Noto Sans"',
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
        '"Noto Color Emoji"',
      ].join(","),
      $mono: [
        "Menlo",
        "Monaco",
        "Consolas",
        '"Liberation Mono"',
        '"Courier New"',
        "monospace",
      ].join(","),
    },
    lineHeights: {
      "$1.35": "1.35",
      "$1.8": "1.8",
    },
    letterSpacings: {
      $normal: "0",
      $wide: "0.025em",
      $wider: "0.05em",
      $widest: "0.1em",
    },
  },
})
